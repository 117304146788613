



















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Prop() tag!: string
  @Prop() loading!: boolean

  searchTag(tagContent: string) {
    const searchText = tagContent.replaceAll('#', '')
    this.$router.push(`/search/forum?text=${searchText}`)
  }
}
